
.menu-category-list-view.alt-style-a {

    
        max-width: 1280px;
        padding: 0 0.3em;
    
    a.item-link {
        color: black;
        display: block;
        text-decoration: none;
        &:visited {
            color: black;
        }
    }
    .menu-item {
        
        background: white !important;
        box-shadow: rgb(48 50 59 / 8%) 0px 3px 10px 0px, rgb(0 0 0 / 1%) 0px 1px 0px 0px, rgb(0 0 0 / 1%) 0px 0px 5px 0px;
        padding: 0 !important;
        overflow: visible !important;
        align-items: stretch !important;
        position: relative;
        // justify-content: center;
        &.halal {
            border: 4px solid rgb(12, 160, 1);
            // background-image: url(https://afag.imgix.net/crave-cafe-west-hills/halal-logo-1.png) !important;
            // background-size: 100px !important;
            // background-repeat: no-repeat !important;
            // background-position: bottom right !important;

            .item-link {

                // background-image: url(https://afag.imgix.net/crave-cafe-west-hills/halal-logo-1.png);
                // background-size: 100px;
                // background-repeat: no-repeat;
                // background-position: bottom right;

            }
            .halal-icon {
                position: absolute;
                z-index: 10;
                left: -15px;
                top: -15px;
                width: 50px;
                display: inline-block;
                img {
                    display: block;
                    width: 100%;
                }
            }
        }
        .item-link {
            width: 80%;
            // overflow: hidden;
        }
        .food-name {
            position: relative;
            z-index: 100;
        }
        .content {
            padding: 1em 0.8em 1em 1em;
            width: auto !important;
        }
        .photo-container {
            // flex: 1;
           
            padding: 0 !important;
            margin: 0 !important;
            border-radius: none;
            img {
                object-fit: cover;
                display: block;  
                height: 100%;
                width: 100%;
                
            }
        }
    
        .item-price {
            margin-top: 0.5em;
            font-size: 0.8em;
            // button link
            // display: inline-block;
            color: black;
            background: white;
            padding: 0.5em 1em;
            border-radius: 0.3em;
            border: 1px solid rgba(0,0,0,0.1);
            text-decoration: none;
            font-size: 0.8em;
            display: inline-flex;
            align-items: center;
            
            // flex-grow: 0;
    
        }
        .add-to-bag-icon {
            width: 1em;
        }
        .dine-in-only {
            font-weight: 800;
            margin-top: 0.5em;
        }
    }
}